import { Box, Collapse, Divider, List, ListItem, Typography } from "@mui/material"
import React, { useState } from "react"

export const CustomizedListItem = ({ item }) => {
    const { stationName, id, address, serialNumber } = item || {};
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <Box sx={{ width: 300, maxWidth: "100%" }}>
            <ListItem button onClick={handleOpen}>
                <Typography sx={{ fontSize: 12 }} color="text.primary">{stationName}</Typography>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="li"
                    sx={{
                        px: 2,
                        cursor: "pointer"
                    }}
                >
                    <Box sx={{ whiteSpace: "pre-wrap", '& p': { fontSize: 12 } }}>
                        <Typography color="text.primary">Id: {id}</Typography>
                        <Typography color="text.primary">Serial No: {serialNumber}</Typography>
                        <Typography color="text.primary">Station Name: {stationName}</Typography>
                        <Typography color="text.primary">Address: {address}</Typography>
                    </Box>
                </List>
            </Collapse>

            <Divider />
        </Box>
    )
}
