import { Box, Divider, IconButton, Typography } from '@mui/material';
import { collection, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { CustomizedListItem } from '../components/RydEFleet/CustomizeListItem';
import GoogleMapWrapper from '../components/common/GoogleMapWrapper';
import MultiSelect from '../components/common/MultiSelect';
import ToggleButton from '../components/common/ToggleButton';
import CustomDataTable from '../components/CustomDataTable';
import { db } from '../firebase/firebase.config';
import CloseIcon from '@mui/icons-material/Close';

const headings = [
    {
        id: 'sl',
        align: "left",
        label: 'No.',
        hidden: true,
    },
    {
        id: 'documentName',
        align: "left",
        label: 'Document Name',
        required: true,
        details: true
    },
    {
        id: 'address',
        align: "left",
        label: 'Address',
    },
    {
        id: 'stationName',
        align: "left",
        label: 'Station Name',
    },
    {
        id: 'showOnMap',
        align: "left",
        label: 'Show On Map',
    },
    {
        id: 'serialNumber',
        align: "left",
        label: 'Serial Number',
    },
];


const RydEFleet = () => {
    const localStorageKey = "charging-station-column";
    const [columns, setColumns] = useState(JSON.parse(localStorage.getItem(localStorageKey) || '["sl", "documentName", "address", "stationName"]'))

    const [isListView, setIsListView] = useState(JSON.parse(localStorage.getItem("isListView") || "false"))
    const [multipleData, setMultipleData] = useState([])
    const [chargingStations, setChargingStations] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        const unsubscribe = onSnapshot(collection(db, "chargingStations"), (doc) => {
            const data = doc.docs.map(item => item.data());
            setChargingStations(data);
            setLoading(false)
        });
        return () => {
            unsubscribe();
        }
    }, [])


    const selectedItem = (lat, lng) => {
        const newData = chargingStations.filter((item) => item.lat === lat && item.lon === lng);
        setMultipleData(newData)
    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ToggleButton value={isListView} setValue={setIsListView} />
            </Box>
            {
                isListView ?
                    <>
                        <MultiSelect
                            localStorageKey={localStorageKey}
                            columns={columns}
                            setColumns={setColumns}
                            totalColumns={headings}
                        />
                        <CustomDataTable
                            columns={columns}
                            rows={chargingStations}
                            loading={loading}
                            headings={headings}
                            uniqueField="documentName"
                            detailsPath="ryd-e-fleet"
                        />
                    </> :
                    <Box sx={{ position: "relative" }} >
                        <GoogleMapWrapper
                            multipleData={multipleData}
                            setMultipleData={setMultipleData}
                            selectedItem={selectedItem}
                            data={chargingStations}
                            type="chargingStations" />
                        <Box sx={{
                            maxHeight: "500px",
                            overflowY: "auto",
                            position: "absolute",
                            top: 10, right: 60,
                            bgcolor: theme => theme.palette.background.paper
                        }}>
                            {
                                multipleData?.length > 0 && (
                                    <>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography sx={{ mt: 1, ml: 2, color: "text.primary" }}>{multipleData.length === 1 ? "Charging Station" : "Charging Stations"}</Typography>
                                            <IconButton onClick={() => setMultipleData([])}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                        <Divider />
                                        {
                                            multipleData.map((item, i) => (
                                                <CustomizedListItem key={i} item={item} />
                                            ))
                                        }
                                    </>
                                )

                            }
                        </Box>
                    </Box>

            }
        </>
    );
};





export default RydEFleet;